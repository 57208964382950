// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

import { withCSS } from "framer"
import Example from "https://framer.com/m/framer/Example.js@^1.0.0"
import { useEffect } from "react"

const css = `
    .supporta-campaign-card {
        border-radius: 0.75rem  !important;
        font-family: 'Poppins'  !important;
        font-size: 1.3em  !important;
    }
    .supporta-project-title {
        display: none;
    }
    .supporta-campaign-card-content h3 {
        font-size: 1.2em !important;
        color: #181717 !important;
        text-transform: uppercase;
    }
    .supporta-search-input {
        border: 1px solid rgb(234 230 228);
        font-family: 'Poppins';
        border-radius: 0.5rem;
    }
    .supporta-show-more-button, .supporta-show-less-button {
        border-radius: 1.5rem;
        font-family: 'Poppins';
        font-size: 14px;
        box-shadow: none;
    }
    .supporta-show-less-button {
        background: white;
    }
    .supporta-campaign-card:hover {
        opacity: 0.8;
    }
    .supporta-campaign-card-content {
        padding: 1.5rem;
    }
    .framer-EcLVq .framer-1xvwtou-container {
        width: 100%;
    }
    .supporta-campaigns-overview {
        gap: 1.5rem;
    }
`

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function ActiesDeelnemersHilversum(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    useEffect(() => {
        const script = document.createElement("script")

        script.src = "https://cdn.supporta.cc/embed.js"
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div>
            <div
                className="supporta-campaign-overview"
                data-charity="218s"
                data-per-page="12"
                data-columns="4"
                data-projects="035zq7zgd6"
                data-include-teams="false"
            ></div>

            <style>{css}</style>
        </div>
    )
}
